
import { Component, Vue } from "vue-property-decorator";

type Messages = {
  success: null | string;
  errors: null | object;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    ForgotPasswordForm: () => import("@/app/modules/auth/components/ForgotPasswordForm.vue")
  }
})
export default class ForgotPasswordPage extends Vue {
  login: string = "" as string;

  messages: Messages = {
    success: null,
    errors: null
  };

  forgotPassword() {
    this.$store
      .dispatch("auth/forgotPassword", { login: this.login })
      .then(({ data }) => {
        this.login = "";
        this.messages = {
          success: data.message,
          errors: null
        };
      })
      .catch(error => {
        this.messages = {
          success: null,
          errors: error.response.data.errors
        };
      });
  }
}
